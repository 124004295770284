<template>
  <div
    class="application-main biote"
  >
    <template v-if="isAuthenticated">
      <NewMainHeader />
        <transition name="slide-fade" mode="out-in">
          <div class="main-content">
            <template>
              <router-view v-if="getAllAvailableLocations" class="content-wrapper" />
            </template>
            <MainFooter class="content-footer" />
          </div>
        </transition>
    </template>
    <template v-else>
      <LoadingScreen />
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { isEmpty } from "lodash";
import NewMainHeader from "@/components/shared-components/master-layout/components/new-main-header/NewMainHeader.vue";
import MainFooter from "@/components/layout/MainFooter.vue";
import LoadingScreen from "@/components/shared-components/LoadingScreen/LoadingScreen.vue";

export default {
  name: "MainContent",
  components: {
    NewMainHeader,
    MainFooter,
    LoadingScreen,
  },
  data() {
    return {
      isAuthenticated: false
    }
  },
  computed: {
    ...mapGetters("locationsModule", ["getAllAvailableLocations"]),
    patientPortal() {
      return `${process.env.VUE_APP_PATIENT_MEDCENTER}`;
    },
    isCallbackRoute() {
      const currentRoute = this.$route;
      return currentRoute.name === "callback" && !isEmpty(currentRoute.query.code);
    },
  },
  beforeRouteUpdate(to, from, next) {
    const oContainer = document.querySelector(".main-content");
    oContainer.scrollTop = 0;
    next();
  },
  async mounted() {
    const { isAuthenticated } = await this.$auth;
    this.isAuthenticated = isAuthenticated;
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .main-loader .anticon {
    font-size: 60px !important;
    color: #ff4d0a;
  }
}
.main-loader {
  height: 75%;
  position: absolute;
  width: 100%;
}
.slide-fade-enter-active {
  transition: all 0.2s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s ease;
}
.slide-fade-enter
/* .slide-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.application-main {
  background-color: #f5f7fa;
  display: flex;
  flex-direction: column;
  height: 100%;

  > *:not(footer):not(.header):not(aside) {
    box-sizing: border-box;
    /* max-width: 1920px; need to refactor site to deal with full width content */
    width: 100%;
    margin: 0 auto;
  }

  footer {
    margin-top: auto;
  }
}

.main-content {
  overflow: auto;
  display: flex;
  height: 100%;
  flex-direction: column;
}

.content-wrapper {
  height: auto;
  width: 100%;
}
.content-footer {
  width: 100%;
  margin-top: auto;
}
</style>
